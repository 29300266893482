.panel {
    width: 100%;
    max-width: 440px;
    margin: 16px auto;
}

.errorText {
    color: var(--color-red);
    margin: 0;
}

.coloredSubmitButton {
    background-color: var(--color-green-washed);
    color: black;
}

.coloredCancelButton {
    background-color: var(--color-red-washed);
    color: black;
}

.label {
    font-weight: bold;
}

.card {
    background-color: white;
    border-radius: var(--border-radius-heavy);
    box-shadow: var(--box-shadow-normal);
    color: black;
    margin: 4px 0;
    text-align: center;
}

.topDetail {
    font-style: italic;
    font-size: small;
    background: var(--color-grey-dark);
    color: white;
    padding: 2px 6px;
    margin: 0;
    border-radius: var(--border-radius-heavy);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: var(--box-shadow-normal-bottom);
    text-align: left;
}

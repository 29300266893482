.iframe {
    display: block;
    height: 440px;
    max-width: 100%;
    /*
        The mounted form has text sharp against the top of the window.
        We'll add some padding, and match the background color to the color of the form.
    */
    padding-top: 20px;
    background-color: #dddfff;
}

.hoursTable td {
    padding-top: 3px;
    padding-bottom: 3px;
}

.day {
    padding-right: 12px;
    font-weight: bold;
    text-align: right;
    font-style: normal;
}
.day::after {
    content: normal !important;
}

.separator {
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
}

.closed {
    text-align: center;
}

/* Styling between weekends and weekdays. */
.hoursTable tr:nth-of-type(6) > th,
.hoursTable tr:nth-of-type(6) > td {
    border-top: solid 1px var(--color-xtransparent-black);
}

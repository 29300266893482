.panel {
    position: relative;
    padding-right: 24px;
}

.error {
    color: var(--color-red);
    border-color: var(--color-red-washed);
}

.dismissButton {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    min-width: unset;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: bold;
    text-transform: unset;
    color: var(--color-red-washed);
    background: none !important;
    border: none !important;
    border-radius: 0;
}

/**
 * Auto-generated by `build-themes.js`, do not edit.
 * 
 * To adjust themes, change `themes.yaml` in the project root, then run `build-themes.js`.
 */

.blackWrapper {
  background-color: hsl(0, 0%, 0%);
  color: white;
}

.blackMain {
  background-color: hsl(0, 0%, 95%);
  color: black;
}

.greenWrapper {
  background-color: hsl(80, 76.8%, 13.5%);
  color: white;
}

.greenMain {
  background-color: hsl(69, 29.8%, 81.6%);
  color: black;
}

.redWrapper {
  background-color: hsl(0, 50%, 50%);
  color: white;
}

.redMain {
  background-color: hsl(0, 0%, 95%);
  color: black;
}

.blueWrapper {
  background-color: hsl(227, 75.3%, 17.5%);
  color: white;
}

.blueMain {
  background-color: hsl(36, 100%, 82.9%);
  color: black;
}

.fadedblueWrapper {
  background-color: hsl(227, 26.1%, 30.8%);
  color: white;
}

.fadedblueMain {
  background-color: hsl(199, 33.3%, 88.8%);
  color: black;
}

.heading {
    font-weight: normal;
}

.xs {
    font-size: 1em;
}

.sm {
    font-size: 1.2em;
}

.md {
    font-size: 1.5em;
}

.lg {
    font-size: 2em;
}

.xl {
    font-size: 2.5em;
}

.bold {
    font-weight: bold;
}

.wrapper {
    margin: 20px 4px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 440px) {
    .wrapper {
        margin: 20px;
    }
}

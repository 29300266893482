.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    color: black;
    background-color: var(--color-transparent-black);
    z-index: 1300;
}

.contents {
    background: var(--color-grey-xlight);
    border-radius: var(--border-radius-normal);
    padding: 24px;
    max-width: 100%;
}

.amountInput > input {
    appearance: textfield;
}

.paymentAmountLabel {
    display: block;
}

.paymentAmountLabelText {
    white-space: nowrap;
    margin-right: 8px;
}

.paymentAmountLabelRestriction {
    color: var(--color-red);
    font-size: small;
    white-space: nowrap;
}

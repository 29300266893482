.card {
    margin: 24px 0;
    width: 600px;
    max-width: 100%;
}

.cardContent {
    padding: 0 4px;
}

.title {
    margin-top: 16px;
}

.due {
    font-size: 1.1em;
}

.scheduledPayment {
    font-size: 1.1em;
}

.panel {
    width: 100%;
    max-width: 440px;
    margin: 16px auto;
}

.notice {
    max-width: 440px;
    margin: 16px auto;
    text-align: justify;
    text-align-last: center;
    font-size: 0.9em;
}

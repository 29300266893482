.label {
    display: block;
    width: 100%;
    max-width: 300px; /* Matches max width of input. */
    text-align: left;
    margin: 8px 0;
    font-weight: bold;
}

.inlineLabel {
    font-weight: normal;
}

.select {
    display: block;
}

.noDealershipText {
    text-align: left;
    max-width: 600px;
}

.buttonList {
    list-style: none;
    padding: 0;
}

.button {
    font-size: 1.1rem;
    text-transform: none;
    font-weight: normal;
    width: 100%;
    max-width: 360px;
}

.menuButton {
    border: none;
    background: none;
    border-radius: var(--border-radius-normal);
    color: var(--color-grey-light);
    font-size: 22px;
    cursor: pointer;
    height: 44px;
    width: 44px;
    min-width: unset; /* Overwrite default button min-width. */
    margin: 0;
    padding: 0 8px;
    flex-shrink: 0;
}

.menuButton:hover,
.menuButton:focus,
.menuButton:active {
    background: var(--color-xtransparent-black);
    border: solid 1px var(--color-xtransparent-white);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--color-transparent-grey);
    z-index: 9; /* Try to ensure the overlay is typically on top of everything else. */
}

.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 360px;
    max-width: 100vw;
    background-color: var(--color-grey-xdark);
    color: white;
    padding: 40px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: right 0.5s ease-in-out;
    z-index: 9; /* Try to ensure the sidebar is typically on top of everything else. */
}

.link {
    font-size: x-large;
    text-decoration: none;
    font-style: normal;
    white-space: nowrap;
    padding: 10px;
    border: solid 1px var(--color-grey-xdark); /* Blends in with background. Only here to prevent displacement when displayed on hover. */
}

.link:hover {
    border-bottom: solid 1px var(--color-grey);
    transition: border 0.4s ease;
}

.collapsed {
    right: -360px;
    transition: right 0.5s ease-in-out;
}

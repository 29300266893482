.privacy {
    max-width: 800px;
    text-align: justify;
    word-spacing:-0.1px;
    /* SRB: Needed to add the word-spacing to stop really bad spacing caused by 
    the justification in the Google Analytics paragraph */
}

.privacy aside {
    text-align: center;
    font-style: italic;
}

.subheading {
    display: block;
    margin-bottom: 1em;
    font-style: italic;
}

.privacy li{
    margin: 10px 0;
}

.indent {
    margin-left: 1.5em;
}
/* Make all elements account for border and padding when calculating width and height. */
* {
    box-sizing: border-box;
}

/* Root */
html {
    /* Variables */
    --color-grey: hsl(215, 10%, 40%);
    --color-grey-dark: hsl(0, 0%, 22%);
    --color-grey-xdark: hsl(0, 0%, 10%);
    --color-grey-light: hsl(0, 0%, 80%);
    --color-grey-xlight: hsl(0, 0%, 95%);
    --color-red: hsl(0, 70%, 50%);
    --color-red-washed: hsl(0, 100%, 80%);
    --color-green: hsl(112, 100%, 68%);
    --color-green-washed: hsl(120, 100%, 80%);
    --color-transparent-black: hsla(0, 0%, 0%, 0.9);
    --color-transparent-grey: hsla(0, 0%, 20%, 0.9);
    --color-transparent-white: hsla(0, 0%, 100%, 0.9);
    --color-xtransparent-black: hsla(0, 0%, 0%, 0.2);
    --color-xtransparent-grey: hsla(0, 0%, 20%, 0.2);
    --color-xtransparent-white: hsla(0, 0%, 1000%, 0.2);

    --border-radius-normal: 4px;
    --border-radius-heavy: 6px;

    --box-shadow-normal: 0 0 2px 1px hsla(0, 0%, 50%, 0.5);
    --box-shadow-normal-bottom: 0 1px 2px 0px hsla(0, 0%, 50%, 0.5);

    /* Styles */
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.5;
    min-width: 320px;
}

/* Anchors */
a {
    color: inherit;
    text-decoration: none;
}
a:hover {
    color: var(--color-grey);
}

/* Abbreviations */

abbr,
abbr[title] {
    text-decoration: underline dotted 1px;
}

/* Buttons */
button {
    border: solid 1px var(--color-grey-dark);
    border-radius: var(--border-radius-normal);
    background-color: var(--color-grey-dark);
    cursor: pointer;
    color: white;
    margin: 8px 0;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 1px;
    padding: 12px;
    text-align: center;
    text-transform: uppercase;
    min-width: 100px;
}
button:not(:disabled):hover,
button:not(:disabled):focus {
    border-color: var(--color-grey);
    background-color: var(--color-grey);
}
button:disabled {
    opacity: 0.6;
    border-color: black;
}

/* Inputs and textareas */
input,
textarea,
select {
    display: block;
    width: 100%;
    max-width: 300px;
    background-color: var(--color-transparent-white);
    border: solid 1px var(--color-grey-light);
    border-radius: var(--border-radius-normal);
    padding: 8px;
    box-shadow: none;
    outline: none;
}
input[type='checkbox'],
input[type='radio'] {
    display: inline;
    width: unset;
    margin-right: 8px;
    margin-left: 4px; /* There's some clipping of radio buttons on firefox without this, especially when highlighted due to being marked required. */
}
input:focus,
textarea {
    border-color: var(--color-transparent-black);
}
input[type='checkbox']:focus,
input[type='radio']:focus {
    outline: dotted 1px grey;
}
input:read-only,
textarea:read-only {
    opacity: 0.6;
}

/* Fieldsets */
fieldset {
    border: solid 1px var(--color-transparent-black);
    border-radius: var(--border-radius-normal);
    padding: 8px;
    margin: 8px 0;
    width: 100%;
    max-width: 300px;
}
legend {
    padding: 0 4px;
    font-weight: bold;
}

/* Forms */
form {
    width: 100%;
    margin: 8px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Tables and related */
table {
    margin: 8px auto;
}
caption {
    font-weight: bold;
    border-bottom: solid 1px var(--color-grey-xdark);
}
th {
    font-weight: normal;
    /* font-style: italic; */
}
th[scope='row'] {
    text-align: right;
}
th[scope='row']::after {
    content: ':';
}
td {
    text-align: left;
}

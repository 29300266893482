.centeredText {
    text-align: center;
}

.form {
    width: 200px;
}

@media screen and (min-width: 460px) {
    .form {
        width: 360px;
    }
}

.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--color-grey-xlight);
    text-align: center;
}

.main {
    flex-grow: 1;
}

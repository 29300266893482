.footer {
    text-align: center;
    padding: 10px 0;
    font-size: small;
}

.footer p {
    margin: 4px;
}

.large {
    font-size: larger;
}

.contactInfo {
    padding: 10px 0;
}

.whitetext {
    color: white;
}

.blacktext {
    color: black;
}

.poweredBy {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0; /* Rely on Frazer logo to set height. This allows better centering of text with logo. */
}

.frazerLink {
    margin-left: 4px;
}

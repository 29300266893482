.card {
    margin: 20px 0;
    padding: 20px 2px;
}

.table {
    margin: 0 auto;
}

.table caption {
    font-weight: bold;
}

.table th {
    text-align: right;
    width: 50%;
}

.table th::after {
    content: ':';
}

.table td {
    text-align: left;
    width: 50%;
}

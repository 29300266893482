.header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    min-height: 60px;
}

.genericHeader {
    margin: 40px 0 0;
}

.pmcLogo {
    max-width: 100%;
}

.genericLogOutLink {
    position: absolute;
    top: 4px;
    right: 8px;
    font-size: 0.8em;
}

.paymycarLogoContainer {
    flex-grow: 1;
    text-align: right;
    margin: 0 20px 0 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    line-height: 0;
}

.homeLink {
    font-size: 1em;
    text-decoration: none;
    color: inherit;
}

.whitetext {
    color: white;
}

.blacktext {
    color: black;
}

@media screen and (min-width: 440px) {
    .homeLink {
        font-size: 1.5em;
    }

    .genericLogOutLink {
        font-size: 0.9em;
    }
}

@media screen and (min-width: 580px) {
    .homeLink {
        font-size: 1.8em;
    }

    .genericLogOutLink {
        font-size: 1em;
    }
}

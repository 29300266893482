.fieldset {
    overflow-y: auto;
    overflow-x: hidden; /* Prevent vertical scroll from creating horizontal scroll on firefox. */
    max-height: 500px;
    /* Override some of the custom global styling for fieldset elements. */
    border: none;
    width: initial;
    max-width: initial;
}

.checkbox {
    font-weight: bold;
}

.cvvDetails {
    font-weight: normal;
}

.contractList {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 600px;
}

.contractCard {
    margin: 16px 0;
    cursor: pointer;
}

.contractCardContent {
    padding: 0 8px 24px;
}

.contractDetails {
    font-weight: bold;
    font-size: 1.1em;
}

.paymentsDisabled {
    font-weight: bold;
}

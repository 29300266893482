.nav {
    padding-top: 10px;
    margin: 10px 20px 10px;
    border-top: solid 1px var(--color-xtransparent-black);
}

.dealershipSpecific {
    border-top: solid 1px var(--color-xtransparent-white);
}

.nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav li {
    margin-bottom: 8px;
}

.nav li:last-of-type {
    margin-bottom: 0;
}

@media screen and (min-width: 540px) {
    .nav {
        max-width: 500px;
        margin: 10px auto 10px;
    }
}

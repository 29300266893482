.wrapper {
    margin: 40px 10px 10px;
    text-align: center;
}

.header {
    margin-bottom: 40px;
}

.text {
    font-weight: bold;
    font-size: large;
}

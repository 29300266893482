.form label {
    font-weight: bold;
    margin-bottom: 8px;
}

.form span {
    font-weight: initial;
    display: block;
}

.form label:last-of-type {
    margin-bottom: 16px;
}
